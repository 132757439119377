* {
    @import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap");
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

body {
    font-family: "Montserrat", sans-serif;
    overflow-x: hidden !important;
}

.Navbar {
    align-items: center !important;
    background-color: white !important;

    &_nav {
        padding: 20px 0 !important;
    }
}

// .cardInterv:hover{
//   .titreIntervention{
//     display:none!important;
//   }
// }

.cardInterv:hover {
    .enSavPlus {
        display: block !important;
        transition: all 0.5s ease;
    }
}
.fixphone {
    padding: 1rem 3rem;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    border-radius: 1rem;
    background-color: #0c9600;
    color: white !important;
    text-decoration: none;
    position: fixed;
    bottom: 1rem;
    right: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 999;
    font-size: 30px;
    @media (max-width: 400px) {
        bottom: 0 !important;
        right: 0 !important;
    }
    @media (max-width: 768px) {
        font-size: 16px;
        padding: 1rem;
    }
}
.phoneone {
    text-decoration: none !important ;
    background-color: var(--color-primary);
    color: #fff;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    border-radius: 1rem;

    &:hover {
        text-decoration: none;
        color: #fff;
        cursor: pointer;
    }
}
.fixphone a {
    font-family: --font-family-monospace;
}
.navbar-active {
    position: fixed !important;
    width: 100%;
    max-width: 100%;
    background-color: #fff !important;
    box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.12);
    border: 0 !important;
    top: 0 !important;
    left: 0 !important;
    transition: 0.2s;
    z-index: 1000 !important;
}

// General Classes
.center {
    display: flex;
    align-items: center;
    justify-content: center;
}
.butn {
    @extend .center;
    width: 200px;
    height: 50px;
    background: #0c9600;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    color: white !important;
    text-decoration: none !important;
    text-align: center;
}
.sub-heading {
    font-weight: 700;
    font-size: 30px;
    color: black;
    text-align: center;
    margin-bottom: 1rem;
    @media (max-width: 960px) {
        font-size: 20px;
    }
}

.mb {
    margin-bottom: 2rem;
}

.mb1 {
    margin-bottom: 1rem;
}

.text {
    font-weight: 400;
    font-size: 16px;
    line-height: 29px;
    @media (max-width: 960px) {
        font-size: 14px;
    }
}
.active .text_green {
    color: #0c9600 !important;
}
.bg_green {
    background-color: #0c9600 !important;
}

// Navbar
.navbar {
    background-color: white !important;
    width: 100vw !important;
    .navbar-brand {
        width: 10%;
    }
    .nav-link {
        color: #000 !important;
        font-weight: 500;
        font-size: 15px;
        font-family: "Montserrat", sans-serif !important;
        @media (max-width: 1100px) {
            font-size: 11px;
        }
    }
    a {
        color: #000 !important;
        font-weight: 500;
        font-family: "Montserrat", sans-serif !important;
    }
    li {
        margin: 0 1.5rem !important;
    }
    .lh {
        line-height: 1 !important;
    }
    .logo {
        width: 120px;
        height: 80px;
        // height: 4rem;
        object-fit: contain;
        image-rendering: pixelated;
    }
    .navbar-toggler {
        color: white !important;
        border: white !important;
        background-color: white !important;
    }
    .dropdown-item {
        font-size: 10px !important;
        &:hover {
            color: #000 !important;
        }
    }
}
.slider_content {
    padding-top: 40px !important;
}
.subtitleService {
    border: 2px solid #0c9600;
    color: #0c9600 !important;
    width: 50%;
    text-align: center;
    margin: auto;
}
.subtitleService2 {
    border: 2px solid #0c9600;
    color: #0c9600 !important;
    width: 70%;
    text-align: center;
    margin: auto;
}
.slider {
    position: relative;
    background: url("../img/refonte/slider2.webp");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    height: 400px;

    &_content {
        &-title {
            font-family: "Montserrat";
            font-style: normal;
            font-weight: 700;
            font-size: 50px;
            line-height: 1.3;
            color: #ffffff;
            margin-bottom: 20px;
        }

        &-p {
            font-family: "Montserrat";
            font-style: normal;
            font-weight: 700;
            font-size: 25px;
            line-height: 1.3;
            color: #ffffff;
            margin-bottom: 30px;
        }
    }
}

.menu {
    padding: 7rem 10rem;

    @media only screen and (max-width: 1400px) {
        padding: 7rem 7rem;
    }

    @media only screen and (max-width: 1200px) {
        padding: 7rem 5rem;
    }

    @media only screen and (max-width: 1000px) {
        padding: 7rem 3rem;
    }

    @media only screen and (max-width: 768px) {
        padding: 5rem 0.7rem;
    }

    &_image {
        &-img {
            width: 90%;
            max-width: 100%;
            height: 660px;

            @media only screen and (max-width: 768px) {
                width: 100%;
                height: 100%;
            }
        }

        &-img2 {
            width: 531px;
            max-width: 100%;
            height: 668px;

            @media only screen and (max-width: 768px) {
                width: 100%;
                height: 100%;
            }
        }

        &-img3 {
            width: 100%;
            height: 400px;
            max-width: 100%;

            @media only screen and (max-width: 768px) {
                width: 100%;
                height: 100%;
            }
        }

        &-img4 {
            width: 100%;
            height: 535px;
            max-width: 100%;

            @media only screen and (max-width: 768px) {
                width: 100%;
                height: 100%;
            }
        }

        &-img5 {
            width: 90%;
            height: 390px;
            max-width: 100%;

            @media only screen and (max-width: 768px) {
                width: 100%;
                height: 100%;
                margin: 10px 0;
            }
        }

        &-img6 {
            width: 100%;
            height: 605px;
            max-width: 100%;

            @media only screen and (max-width: 768px) {
                width: 100%;
                height: 100%;
            }
        }
    }

    &_content {
        &-title {
            font-family: "Montserrat";
            font-style: normal;
            font-weight: 700;
            font-size: 30px;
            line-height: 1.7;
            text-align: center;
            color: #000000;
        }

        &-p {
            font-family: "Montserrat";
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 1.2;
            text-align: justify;
            color: #000000;

            @media only screen and (max-width: 1000px) {
                font-size: 20px;
            }

            @media only screen and (max-width: 768px) {
                font-size: 18px;
            }
        }

        &-p2 {
            font-family: "Montserrat";
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 1.3;
            text-align: justify;
            color: #000000;
        }

        &-p3 {
            font-family: "Montserrat";
            font-style: normal;
            font-weight: 400;
            font-size: 11px;
            line-height: 1.3;
            text-align: justify;
            color: #000000;
        }

        &-p4 {
            font-family: "Montserrat";
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 1.3;
            text-align: justify;
            color: #000000;

            @media only screen and (max-width: 768px) {
                font-size: 18px;
            }
        }
    }

    &_ul2 {
        list-style: none;
        margin-top: 20px;
    }

    &_ul {
        list-style: none;
        margin-top: 20px;
        margin-left: -8rem !important;
        margin-right: -8rem !important;

        @media only screen and (max-width: 1000px) {
            margin-left: 0 !important;
            margin-right: 0 !important;
        }

        &-li {
            font-family: "Montserrat";
            font-style: normal;
            font-weight: 400;
            font-size: 24px;
            line-height: 1.3;
            text-align: justify;
            color: #000000;
            margin: 10px 0;

            @media only screen and (max-width: 768px) {
                font-size: 18px;
            }
        }

        &-li2 {
            font-family: "Montserrat";
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 1.3;
            text-align: justify;
            color: #000000;
            margin: 10px 0;
        }

        &-icon {
            font-size: 18px;
            color: #0c9600;
            font-style: normal;
            font-family: "Font Awesome 5 Free";
            margin-right: 20px;
        }
    }
}

.menuantimage {
    width: 100%;
    max-width: 100%;
    height: 400px;

    @media only screen and (max-width: 768px) {
        margin: 10px 0;
    }
}

.card-home {
    padding: 0.5rem 1rem;
    border: 1px solid #c4c4c4;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 325px;
    @media (max-width: 960px) {
        width: fit-content;
        height: 200px
    }
    text-align: center;
    text-decoration: none !important;
    position: relative;
    .butn{
        padding: 10px 25px;
        display: block;
        width: fit-content;
        margin: auto;
        position: relative;
        text-align: center;
        transition: 0.3s;
        &::after{
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            content: "";
            background: white;
            z-index: -1;
            width: 0px;
            height: 0px;
            transition: 0.3s;
            display: block;
        }
        &:hover{
            background: #0c9600 !important;
            color: white !important;
            &::after{
                width: 500px;
                height: 400px;
            }
        }
    }
    .trigger{
        position: absolute;
        left: 0px;
        top: 0px;
        right: 0px;
        bottom: 0px;
        overflow: hidden;
        &::after{
            position: absolute;
            content: "";
            left: 50%;
            top: 50%;
            width: 0px;
            height: 0px;
            transform: translate(-50%, -50%);
            background: #0c9600;
            transition: 0.3s;
            z-index: -5;
        }
        &:hover{
            &::after{
                width: 100%;
                height: 100%;
            }
            .butn{
                background: white;
                color: #0c9600 !important;
                &:hover{
                    color: white !important;
                }
            }
        }
    }
    .img-wrap {
        position: relative;
        z-index: 2;
        img {
            border-radius: 50%;
            margin: 25px auto;
            display: block;
            height: 100px;
            width: 150px;
            object-fit: contain;
            width: 5rem;
        }
    }
    .text-wrap {
        position: relative;
        z-index: 2;
        margin-top: 50px;
        .card-head {
            font-weight: 700;
            font-size: 11px;
            color: #000000;
            margin-bottom: 1rem;
        }
        .card-text {
            font-weight: 400;
            font-size: 9px;
            line-height: 11px;
            color: #000000;
        }
    }
}

.logos {
    width: 100%;
    flex-direction: column;
    margin-bottom: 6rem;
    .sub-heading {
        margin-bottom: 4rem;
    }
    .logo-wrap {
        flex-wrap: wrap;
        width: 100%;
        img {
            margin: 1rem 2rem;
        }
    }
}
.banner {
    height: 40vh;
    width: 100vw;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    margin-bottom: 5rem;
    @extend .center;
    .banner-head {
        font-weight: 700;
        font-size: 45px;
        color: white;
        text-align: center;
        @media (max-width: 960px) {
            font-size: 20px;
        }
    }
}
.wrap-items {
    @extend .center;
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-between;
    @media (max-width: 960px) {
        justify-content: center;
    }

    @media only screen and (max-width: 768px) {
        justify-content: center !important;
        align-items: center !important;
    }
    img {
        width: 32%;
        margin: 1rem 0;
        @media (max-width: 960px) {
            width: 15rem;
        }
    }
    .gal-img {
        width: 30%;
        height: 20rem;
        margin: 1rem 0;
        @media (max-width: 960px) {
            width: 15rem;
            margin: 10px !important;
        }
        img {
            object-fit: cover !important;
            width: 100% !important;
            height: 100% !important;
        }
    }
    .wrp-img {
        width: 45%;
        @media (max-width: 960px) {
            width: 20rem;
        }

        @media only screen and (max-width: 768px) {
            width: 100%;
            max-width: 100%;
            height: 100%;
            margin: 10px 0;
        }
    }
    .content {
        @extend .center;
        padding: 2rem;
        flex-direction: column;
        border: 1px solid #9a9a9a;
        width: 350px;
        height: 279.91px;
        margin: 1rem 0;
        filter: drop-shadow(0px 4px 14px rgba(0, 0, 0, 0.25));

        @media only screen and (max-width: 768px) {
            width: 100%;
        }
        img {
            width: 4rem;
        }
        .num {
            font-weight: 700;
            font-size: 30px;
            line-height: 38px;
            @media (max-width: 960px) {
                font-size: 18px;
            }
        }
        .email {
            font-weight: 700;
            font-size: 22px;
            line-height: 38px;
            text-align: center;
            @media (max-width: 960px) {
                font-size: 18px;
            }
        }
        .address {
            font-weight: 700;
            font-size: 18px;
            line-height: 38px;
            text-align: center;
            @media (max-width: 960px) {
                font-size: 15px;
            }
        }
    }
}

.greenbg {
    width: 100vw;
    @extend .center;
    background: #0c9600;
    flex-direction: column;
    padding: 1rem 0;
    margin: 6rem 0;
    .bghead {
        font-weight: 700;
        font-size: 36px;
        line-height: 44px;
        text-align: center;
        color: white;
        @media (max-width: 960px) {
            font-size: 23px;
        }
    }
    .bgtext {
        color: white;
    }
}
h1 {
    text-transform: uppercase !important;
}
.dropdown-item:hover {
    background-color: #0c9600 !important;
    a {
        color: white !important;
    }
}
// General section styles End
.text_green {
    color: #0c9600;
}
// Footer
.footer {
    background: #ffffff !important;
    color: black !important;
    a {
        color: black !important;
        text-decoration: none;
        margin-bottom: 0.5rem;
    }
    .text {
        max-width: 700px;
    }
    ul {
        @extend .center;
        align-items: flex-start;
    }
    li {
        @extend .center;
        img {
            margin-bottom: 0.5rem;
        }
    }
}

// Home page
.home {
    width: 100vw;
    padding: 0 5rem;
    flex-direction: column;
    @media (max-width: 960px) {
        padding: 0 2rem;
    }
    .home-one {
        position: relative;
        margin-top: 3rem;
        width: 100%;
        background-image: url("../img/homeone.png");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        flex-direction: column;
        padding: 6rem 0;
        h3 {
            text-align: center;
            font-weight: 700;
            font-size: 39px;
            color: white;
            text-shadow: #0c9600 5px 0px 0px;
            @media (max-width: 960px) {
                font-size: 22px;
            }
        }
        .text {
            color: white;
            font-size: 25px;
            font-weight: 600;
            margin: 1rem 0;
            text-align: center;
            @media (max-width: 960px) {
                font-size: 18px;
            }
        }
        .btn-wrap {
            position: absolute;
            bottom: -2rem;
        }
    }
    .home-two {
        width: 100%;
        @extend .center;
        flex-direction: column;
        margin-top: 8rem;
        @media (max-width: 960px) {
            margin-top: 4rem;
        }
        .text {
            margin-bottom: 3rem;
            text-align: center;
        }
        .card-wrap {
            width: 100%;
            flex-wrap: wrap;
            justify-content: center;
            .card-home {
                margin: 0 1.5rem 1.5rem 1.5rem;
            }
            @media (max-width: 960px) {
                margin: 0 0rem 0rem 0rem;
            }
        }
    }
    .home-three {
        margin-top: 5rem;
        flex-direction: column;
        width: 100%;
        .content-wrap {
            width: 100%;
            justify-content: center;
            flex-wrap: wrap;
            .content {
                width: 500px;
                justify-content: flex-start;
                margin-bottom: 2rem;
                img {
                    margin-right: 1rem;
                }
                .text {
                    max-width: 500px;
                }
                @media (max-width: 960px) {
                    width: 80vw;
                    // justify-content: center;
                }
            }
        }
    }
    .home-four {
        width: 100%;
        flex-direction: column;
        margin-top: 5rem;
        .sub-heading {
            margin-bottom: 4rem;
        }
        .content-wrap {
            @extend .center;
            width: 100%;
            justify-content: center;
            flex-wrap: wrap;
            .content {
                width: fit-content;
                margin: 0 1.3rem 2rem 0;
                @extend .center;
                flex-direction: column;
                background: #ffffff;
                box-shadow: 5px 5px 9px 3px rgba(0, 0, 0, 0.25);
                padding: 1.5rem;
                img {
                    width: 5rem;
                    margin-bottom: 1rem;
                }
                .text {
                    font-weight: 700;
                    font-size: 19px;
                    line-height: 23px;
                    max-width: 300px;
                    text-align: center;
                }
            }
        }
        .butn {
            margin-top: 2rem;
        }
    }
    .widget {
        height: 100vh;
    }
}

// About page
.about {
    width: 100vw;
    padding: 0 5rem;
    flex-direction: column;
    @media (max-width: 960px) {
        padding: 0 2rem;
    }
    .sub-heading {
        // margin-top: 3rem;
    }
    .btn-wrap {
        width: 100%;
        margin: 4rem 0;
    }
}

// GUEPES PAGE
.guepes {
    width: 100vw;
    padding: 0 5rem;
    flex-direction: column;
    @media (max-width: 960px) {
        padding: 0 2rem;
    }
    .ant-image {
        width: 80%;
        max-width: 100%;
        image-rendering: pixelated;
        margin-bottom: 2rem;
        @media (max-width: 500px) {
            width: 90%;
        }
    }
}

// Rongueur page
.template {
    width: 100vw;
    padding: 0 5rem;
    flex-direction: column;
    @media (max-width: 960px) {
        padding: 0 2rem;
    }
    .text-green {
        font-weight: 700;
        font-size: 16px !important;
        line-height: 27px;
        text-align: center;
        color: #0c9600;
        margin: 3rem 0 0 0;
        @media (max-width: 960px) {
            font-size: 15px;
        }
    }
    .content-ram {
        width: fit-content;
        margin-bottom: 1rem;
        align-items: flex-start;
        img {
            margin-right: 1rem;
        }
        .text {
            max-width: 500px;
        }
    }
    .wrap-ram {
        margin-top: 3rem;
        .text {
            // max-width: 200px;
            text-align: center;
            margin-bottom: 1.5rem;
        }
    }
}
.intervention {
    width: 100vw;
    flex-direction: column;
    .img-wrap {
        width: fit-content;
        position: relative;
        .map {
            width: 90%;
            image-rendering: pixelated;
        }
        .lipse {
            width: 50%;
            position: absolute;
            top: 35%;
            left: 40%;
            @media (max-width: 768px){
                max-width: 50%
            }
        }
        @media (max-width: 768px){
            width: 100% !important
        }
    }
}
// Devis page
.devis {
    width: 100vw;
    padding: 0 5rem;
    @media (max-width: 960px) {
        padding: 0 2rem;
    }
    .form {
        width: 90%;
        @media (max-width: 960px) {
            width: 100%;
        }
        .sub-heading {
            text-align: left;
        }
        .input-wrap {
            width: 100%;
            display: flex;
            justify-content: space-between;
            input,
            select,
            textarea {
                background: rgba(196, 196, 196, 0.26);
                border: none;
                padding: 0 0 0 1rem;
            }
            select {
                height: 49px;
                margin-bottom: 1rem;
            }
            input {
                height: 44px;
                margin-bottom: 1rem;
                // margin-right: 0.5rem;
                width: 48%;
            }
            textarea {
                padding: 1rem 0 0 1rem;
            }
            .max-len {
                width: 100% !important;
            }
        }
        .btn-wrap {
            width: 100%;
        }
    }
    iframe {
        width: 100%;
        height: 60vh;
        @media (max-width: 960px) {
            height: 50vh;
        }

        @media only screen and (max-width: 768px) {
            width: 100% !important;
            max-width: 100%;
        }
    }
}
// Gallery Page
.gallery {
    .links-wrap {
        .link-gal {
            margin: 0 1rem;
            text-decoration: none;
            color: #333;
        }
    }
}
.hide {
    display: none;
}
.upgalary {
    animation: upgal 1s;
}
@keyframes upgal {
    0% {
        transform: translateY(50px) scale(0.8);
    }
    100% {
        transform: translateY(0px) scale(1);
    }
}

.pfive {
    padding: 0 7rem;
}

.phonelink {
    color: #000;
    text-decoration: none;

    &:hover {
        cursor: pointer;
    }
}

/* --------------------------------------telephone -------------------------------------------- */

@media only screen and (max-width: 767px) {
    /* */
    body {
        overflow-x: hidden !important;
    }
    .elfsight-app-4866ce13-1d3c-4a39-b052-fb0795ceed39 {
        width: 361px !important;
    }
    section {
        overflow-x: hidden !important;
    }
    .fixphone {
        right: 100px !important;
    }
    .elfsight-app-4866ce13-1d3c-4a39-b052-fb0795ceed39
        .eapps-facebook-feed-small {
        width: 100% !important;
    }
    .slider_content-title {
        font-size: 25px !important;
    }
    .titreIntervention {
        background: rgba(0, 0, 0, 0.7) !important;
    }
    #logo_sm_md {
        width: 66px !important;
        margin-left: 10px !important;
    }
    .typeIntervention {
        margin-bottom: 20px !important;
    }
    .img_sm {
        height: inherit !important;
    }
    .mt_5 {
        margin-top: 1rem !important;
    }
    .ml_20 {
        margin-left: 0px !important;
    }
    .slider {
        height: 230px;
        margin-bottom: 20px !important;
    }
    .slider_content-title {
        font-size: 18px !important;
    }
    .slider_content {
        padding-top: 16px !important;
    }
    .slider_content-p {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 400;
        font-size: 17px;
        line-height: 1;
        color: #ffffff;
        margin-bottom: 30px;
    }
    .navbar li {
        margin: 10px 1.5rem !important;
    }
    .picto_sm {
        width: 10% !important;
        padding-bottom: 10px;
        padding-top: 10px;
    }
    .picto_sm2 {
        width: 25% !important;
        padding-bottom: 10px;
        padding-top: 10px;
    }
    .links-wrap {
        display: inline-flex !important;
        flex-wrap: wrap;
        flex-direction: column;
        text-align: center;
        line-height: 8px;
    }
    .link-gal {
        font-size: 12px !important;
        font-weight: 700 !important;
        margin: 5px !important;
    }
    .card-home {
        width: 100% !important;
    }
    .dnone_sm {
        display: none !important;
    }
    .logo_footer {
        margin: auto;
        display: flex;
        width: 46% !important;
    }
    .text_ceter_sm {
        text-align: center !important ;
    }
    .mt_10_sm {
        margin-top: 10px !important;
    }
    .m_auto_sm {
        margin: auto !important ;
        display: block !important ;
        text-align: center !important;
    }
    .liens_utiles_sm {
        bottom: 110px !important;
        margin: auto !important ;
        display: block !important ;
        li {
            margin: auto !important ;
            display: block !important ;
        }
    }
    .subtitleService {
        width: 100% !important;
        font-size: 21px !important;
    }
    .subtitleService2 {
        width: 100% !important;
        font-size: 21px !important;
    }
    .imgIcodera {
        margin: auto !important;
        height: 80px;
    }
    .card-head {
        text-align: center !important;
    }
    .col-md-12 {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
    .center {
        display: grid !important;
        align-items: center !important;
        justify-content: inherit !important;
    }
    .card-home .img-wrap {
        width: 60px !important;
        margin: auto !important;
        padding-bottom: 10px;
    }
    img {
        width: 100% !important;
    }
    .menu_content {
        margin: 0rem !important;
    }
    .icoRamo_sm {
        height: 90px !important;
        width: 90px !important;
    }
    .fixphone {
        bottom: 20px !important;
        right: 14px !important;
    }
    .my_5 {
        margin-top: 0rem !important;
    }
    .titre_Sm_acc {
        padding-top: 8px !important;
    }
}
.slider {
    position: relative;
    margin-bottom: 60px;
}
.my_5 {
    margin-top: 3rem;
}
.w_75 {
    width: 75%;
}
.w_100 {
    width: 100%;
}
.mt_5 {
    margin-top: 3rem;
}
// Imports
@import "bootstrap";

.dropdown.active {
    a.dropdown-item {
        color: $body-color !important;
        &.active {
            color: $white !important;
        }
    }
}

img {
    border-radius: 10px !important;
    @media (max-width: 768px) {
        height: auto !important;
    }
}

strong {
    color: #0c9600;
}

@media (max-width: 768px) {
    .fTwWLu {
        transform: scale(0.75);
        transform-origin: left bottom;
    }
}

.styled-list {
    li {
        position: relative;
        margin-left: 30px;
        &::after {
            position: absolute;
            left: -30px;
            content: "\f058";
            font-family: "Font Awesome 5 Free";
            font-weight: 900;
            color: #0c9600;
            top: 0px;
        }
        @media (max-width: 768px) {
            text-align: left !important;
        }
    }
}

.outer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($color: #000000, $alpha: .5);
}
